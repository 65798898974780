.swal2-styled.swal2-confirm {
    border-radius: 8px !important;
    background-color: #103996 !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}

/*.MuiBackdrop-root{
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.99) 0%, rgba(22, 28, 36, 1) 100%) !important;
}*/

.MuiModal-root{
    z-index: 10 !important;
}

.MuiSelect-select{
    padding: 7.5px 14px !important;
}

.MuiTableSortLabel-icon, .MuiTableSortLabel-root{
    color: white !important;
}

.tabsDivider:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 20%;
    height: 30px;
    width: 50%;
    border-right:1px solid #919eab3d;
  }

.MuiButton-contained{
    color: white;
}